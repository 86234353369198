input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	display: none;
}

input[type="date"]::-webkit-inner-spin-button {
	display: none;
}
.input_base input[type="date"]::-ms-clear {
	display: none;
}

.on-sale-cap {
	text-transform: capitalize;
}

.css-13cymwt-control {
	border-width: 0px !important;
}

/* .slick-cloned {
	display: none !important;
} */

.btn {
	border-radius: 0px !important;
	border: none !important;
}

#UIloader-2 {
	width: 100%;
	height: 70vh;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.7);
}

#UIloader-2::after {
	content: "";
	display: block;
	position: absolute;
	left: 48%;
	top: 40%;
	width: 40px;
	height: 40px;
	border-style: solid;
	border-color: #4472c7;
	border-top-color: transparent;
	border-width: 4px;
	border-radius: 50%;
	-webkit-animation: spin 0.8s linear infinite;
	animation: spin 0.8s linear infinite;
}

/* Works on Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: #4472c7 transparent;
}

.plus-div {
	position: relative;
}
.plus-div span {
	position: absolute;
	top: 11px;
	left: 16px;
}

.min-height-nft-view {
	min-height: 100% !important;
}
/* Works on Chrome, Edge, and Safari */
/* *::-webkit-scrollbar {
	width: 3px;
}

*::-webkit-scrollbar-track {
	background: transparent;
}

*::-webkit-scrollbar-thumb {
	background-color: #4472c7;
	border-radius: 10px;
	border: 0px solid orange;
} */

.left-side-column.fillter-bar-hide .right-side-col,
.left-side-column.fillter-bar-show.abc .right-side-col {
	position: unset !important;
}

.collection-truc {
	display: inline-block;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.Pro-icon {
	position: relative;
}

.Pro-icon p {
	position: absolute;
	top: -5px;
	left: 10px;
	height: 8px !important;
	width: 8px !important;
}

.cancel-list-btn {
	margin: 12px 0 0 0 !important;
}

.edit-btn {
	margin-top: 10px !important;
}

.btn-secoundry {
	color: black !important;
	background-color: #e8e9e9 !important;
}

.btn-secoundry:hover {
	color: white !important;
	background-color: #4472c7 !important;
}

.btn-create-home {
	background-color: #a8967c !important;
	font-weight: 500 !important;
	color: white !important;
}

.btn-create-home {
	font-weight: 500 !important;
}

.pb-2xsz {
	padding-bottom: 0.1rem !important;
}
.btn-primary-explore {
	font-family: "poppins";
	font-weight: 500 !important;
}

.approve-btn-resale {
	font-weight: 600 !important;
}

.clear-all-btn a:hover {
	color: #000 !important;
}

ul.propert-details-list li:last-child {
	border-right: none;
}
